import React from "react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { VirtualTeam } from "../../../types/models/Team";
import { theme } from "../../../themes/theme";
import TeamTableRow from "./TeamTableRow";
import EmptyResult from "../../rules/EmptyResult";

interface TeamTableProp {
    teams: VirtualTeam[] | undefined,
    onOpenEditDialog: (team: VirtualTeam) => void
}

const TeamTable: React.FC<TeamTableProp> = (prop) => {

    const SkeletonRow = () => {
        return <TableRow >
            {Array.from({ length: 5 }, (_, i) => <TableCell key={i} height={"52px"}><Skeleton variant="text" height='1.5em' /></TableCell>)}
        </TableRow>
    };

    return (
        <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
            <Table size="small">
                <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor }}>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell sx={{width: "40%"}}>Leaders</TableCell>
                        <TableCell>Members</TableCell>
                        <TableCell sx={{width: "15%"}}>Owner</TableCell>
                        {/* <TableCell sx={{ pr: 0 }}>Start Date</TableCell>
                        <TableCell sx={{ pr: 0 }}>End Date</TableCell> */}
                        <TableCell sx={{ px: 0 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prop.teams === undefined ?
                        (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        )
                        :
                        (
                            prop.teams.length === 0 ?
                                <TableRow>
                                    <TableCell sx={{ p: 0 }} colSpan={8}>
                                        <EmptyResult>No teams available</EmptyResult>
                                    </TableCell>
                                </TableRow>
                                :
                                prop.teams.map((team) => (
                                    <TeamTableRow
                                        key={team.TeamId}
                                        team={team}
                                        onOpenEditDialog={prop.onOpenEditDialog}
                                    />
                                ))
                        )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TeamTable;
