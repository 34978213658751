import React, { useState } from "react"
import { IconButton, Paper, Skeleton, Snackbar, SnackbarContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import FileSaver from "file-saver";
import { AppState } from "../../redux/store";
import { downloadPDFBlob } from "../../redux/actions/RuleFile";
import { formatDateTime } from "../../utils/StringUtils";
import { RuleFile } from '../../types/models/RuleFile';
import { RuleFileState } from "../../types/models/RuleFileState";
import { theme } from "../../themes/theme";
import EmptyResult from "../rules/EmptyResult";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface DownloadFileTableProp {
    filteredRuleFileList: RuleFile[] | undefined
}

const baseURL =  process.env.REACT_APP_API_BASE_URL;

const DownloadFileTable: React.FC<DownloadFileTableProp> = (prop) => {

    const dispatch = useDispatch();
    const fileState = useSelector<AppState, RuleFileState>((state) => state.file);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const SkeletonRow = () => {
        return (
            <TableRow>
                {Array.from({ length: 3 }, (_, i) => <TableCell key={i} height={"52px"}><Skeleton variant="text" height='1.5em' /></TableCell>)}
            </TableRow>
        );
    }

    const copyToClipboard = async (textToCopy: string) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        }
        catch (error) {
            console.log(`failed to copy to clipboard - ${error}`);
        }
    }

    const downloadRuleFile = (file: RuleFile) => {
        setDownloading(true);
        dispatch(downloadPDFBlob(file.ContentResourceId, (blob) => {
            setDownloading(false);
            if (blob) {
                FileSaver.saveAs(blob, `${file.DisplayName}.pdf`);
            }
        }));
    };


    const getFileRows = () => {
        if (prop.filteredRuleFileList === undefined) {
            // Calling ListPDFs API
            return Array.from({ length: 2 }, (_, i) => <SkeletonRow key={i} />);
        } else if (prop.filteredRuleFileList.length === 0) {
            // No files available
            return (
                <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={3}>
                        <EmptyResult>{
                            (fileState.searchText && fileState.searchText.length > 0) ?
                                "No items matching your current search." : "PDF files are currently unavailable."}
                        </EmptyResult>
                    </TableCell>
                </TableRow>
            );
        } else {
            return prop.filteredRuleFileList.map(file =>
            (
                <TableRow key={file.ContentRevisionId}>
                    <TableCell>{file.DisplayName}</TableCell>
                    <TableCell>{formatDateTime(file.CreatedAt)}</TableCell>
                    <TableCell>
                        <Tooltip title="Copy Link">
                            <IconButton
                                sx={{ color: theme.palette.primary.main }}
                                disabled={downloading}
                                aria-label="download"
                                onClick={() => copyToClipboard(`${baseURL}/content/latest/${file.ContentResourceId}`)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                        <IconButton
                            sx={{ color: theme.palette.primary.main }}
                            disabled={downloading}
                            aria-label="download"
                            onClick={() => downloadRuleFile(file)}>
                            <CloudDownloadOutlinedIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ));
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                open={isCopied}
                autoHideDuration={6000}
            > 
                <SnackbarContent style={{
                    backgroundColor: theme.palette.success.main,
                }}
                message={<span>Copied to clipboard!</span>}
                />
            </Snackbar>
            <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
                <Table size="small">
                    <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor }}>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell width={"120px"} align="center">Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getFileRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DownloadFileTable;
