import React, { ReactElement, useCallback, useState } from "react";
import { Button, Card, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { theme } from "../../../themes/theme";
import TeamMemberRow from "./TeamMemberRow";
import EmptyResult from "../../rules/EmptyResult";
import { TeamUser } from "../../../types/models/TeamUser";
import { canUpdateTeam } from "../TeamUtils";
import { VirtualTeam } from "../../../types/models/Team";

interface TeamMembersTableProp {
    team: VirtualTeam;
    isLeader: boolean;
    users: TeamUser[];
    onEdit: () => void;
    onDelete: (userId: string) => void;
}

const TeamMembersTable: React.FC<TeamMembersTableProp> = (prop) => {
    const [loadMore, setLoadMore] = useState<boolean>(false);

    const getMemberRows = useCallback(() => {
        const initialRowCount = 3;
        const displayLoadMore = (!loadMore && prop.users.length > initialRowCount);
        const displayUserCount = loadMore ? prop.users.length : (prop.users.length > initialRowCount ? initialRowCount : prop.users.length);

        if (prop.users.length === 0) {
            return (
                <TableRow >
                    <TableCell sx={{ p: 0 }} colSpan={8}>
                        <EmptyResult>No users available</EmptyResult>
                    </TableCell>
                </TableRow>);
        } else {
            const rows: ReactElement[] = prop.users.slice(0, displayUserCount).map((user) => (
                <TeamMemberRow team={prop.team} user={user} key={user.UserId} onDelete={prop.onDelete}></TeamMemberRow>
            ));

            if (displayLoadMore) {
                rows.push(
                    <TableRow key="load_more_row">
                        <TableCell colSpan={3}>
                            <Link sx={{ textDecoration: "none", cursor: "pointer" }} onClick={() => setLoadMore(true)}>Load More...</Link>
                        </TableCell>
                    </TableRow>);
            }
            return rows;
        }

    }, [loadMore, prop.onDelete, prop.users])

    return (
        <Card sx={{ px: 1, pb: 1, width: "100%", backgroundColor: theme.table.subHeaderBgColor, borderRadius: 3 }}>
            <TableContainer sx={{ borderRadius: 3 }}>
                <Table size="small" >
                    <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor, "& .MuiTableCell-root": { pb: 0, borderBottom: "none" } }}>
                        <TableRow>
                            <TableCell>{prop.isLeader ? "Leader" : "Member"}</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell align="center">
                                <Button
                                    disabled={!canUpdateTeam(prop.team)}
                                    startIcon={<AddCircleOutlineOutlinedIcon />}
                                    variant="text"
                                    size="small"
                                    sx={{ textTransform: "none", mb: 0.5 }}
                                    onClick={() => prop.onEdit()}>
                                    {"Add"}
                                </Button></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "white", "& .MuiTableRow-root": { "&:last-child td": { borderBottom: "none" } } }} >
                        {getMemberRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default TeamMembersTable;
