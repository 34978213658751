import React, { useState } from "react";
import { Paper, TableCell, TableRow, IconButton, Popper, Grow, ClickAwayListener, MenuList, MenuItem, Box } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { theme } from "../../../themes/theme";
import { TeamUser } from "../../../types/models/TeamUser";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store";
import { TeamMembersState } from "../../../types/models/TeamMembersState";
import { canUpdateTeam, getHighlightedElement } from "../TeamUtils";
import { VirtualTeam } from "../../../types/models/Team";

interface TeamMemberRowProp {
    team: VirtualTeam;
    user: TeamUser;
    onDelete: (userId: string) => void;
}

const TeamMemberRow: React.FC<TeamMemberRowProp> = ({ team, user, onDelete }) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);

    return (
        <TableRow key={user.UserId} >
            <TableCell>
                {getHighlightedElement(user.DisplayName, teamState.orgTeamSearchText)}
            </TableCell>
            <TableCell>{getHighlightedElement(user.Email, teamState.orgTeamSearchText)}</TableCell>
            {/* <TableCell>{user.roles.join(", ")}</TableCell> */}
            <TableCell sx={{ px: 0 }} align="center">
                <IconButton
                    disabled={!canUpdateTeam(team)}
                    ref={anchorRef}
                    onClick={() => { setIsOpenMenu((prevOpen) => !prevOpen); }}>
                    <MoreVertIcon />
                </IconButton>
                <Popper
                    open={isOpenMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper sx={{ backgroundColor: "white", zIndex: 9999 }}>
                                <ClickAwayListener onClickAway={() => setIsOpenMenu(false)}>
                                    <MenuList
                                        autoFocusItem={isOpenMenu}>
                                        <MenuItem
                                            sx={{ color: theme.palette.primary.main }}
                                            onClick={() => {
                                                setIsOpenMenu(false);
                                                onDelete(user.UserId ?? "");
                                            }} >
                                            <DeleteOutlineOutlinedIcon />
                                            <Box sx={{ ml: 1 }}>Delete</Box>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </TableCell>
        </TableRow >)
};

export default TeamMemberRow;
