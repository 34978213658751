import React, { ReactNode, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import _ from 'lodash';
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { Paragraph } from "../../../types/models/Paragraph";
import { fontColor } from "../../../constants/ColorSets";
import { getRuleInstanceMetadata } from "../../../utils/RuleUtil";
import { splitSearchText } from "../../../utils/StringUtils";
import { ContentRevision } from "../../../types/models/ContentRevision";

interface Alert {
  CreatedAt: string | null | undefined;
  RuleInstanceId: string;
  RuleId: string;
  Filename: string;
  DisplayName: string;
  ShouldNotify?: boolean | undefined;
  Metadata?: string | undefined;
  Roles: string[];
  Paragraphs: Paragraph[];
}

interface RuleInstanceListProps {
  ruleInstances: RuleInstance[] | undefined;
  onViewRuleDetail: (rule: RuleInstance) => void;
  narrowRowSpace?: boolean;
  invisiblePinIcon?: boolean;
  secondaryTextFunction?: (rule: RuleInstance) => string | undefined;
  footerElement?: (rule: RuleInstance) => ReactNode | undefined;
}

const RuleInstanceList: React.FC<RuleInstanceListProps> = (prop) => {
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const [searchParams] = useSearchParams();
  const linkedRuleId = searchParams.get("ruleId");

  useEffect(() => {
    // Trigger the view detail action outside of the render cycle
    const rule = prop.ruleInstances?.find(rule => rule.RuleId === linkedRuleId);
    if (rule) {
      prop.onViewRuleDetail(rule);
    }
  }, [linkedRuleId, prop.ruleInstances, prop.onViewRuleDetail]);

  function getHighlightedRuleText(paragraphs: Array<Paragraph>, searchText: string) {

    if (!searchText) return (<Box></Box>);

    const searches = splitSearchText(searchText);
    // Find first paragraph matching with one of search texts
    const paragraph = paragraphs.find(paragraph => {
      let result = false;
      searches.forEach(searchText => {
        if (!result && paragraph.TextContent.toLowerCase().includes(searchText.toLowerCase())) {
          result = true;
        }
      });
      return result;
    });

    if (paragraph) {
      let searchedIndex = paragraph.TextContent.length;
      let findText = "";
      searches.forEach(searchText => {
        // Find first index matched with search texts
        const index = paragraph.TextContent.toLowerCase().indexOf(searchText.toLowerCase());
        if (index > -1 && searchedIndex > index) {
          searchedIndex = index;
          findText = searchText;
        }
      });

      let text = paragraph.TextContent;
      if (searchedIndex > 80) {
        text = "..." + text.substring(searchedIndex - 20);
      }

      return (
        <Typography sx={{
          color: 'text.secondary',
          fontSize: 13,
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: "break-all",
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical'
        }}>
          {getHighlightedText(text, findText)}
        </Typography>
      );
    } else {
      return (<Box></Box>);
    }
  }

  const getSearchedWordNumber = (paragraphs: Array<Paragraph>, searchText: string) => {
    let searchCount = 0;

    if(!searchText) return <Box></Box>;

    const searches = splitSearchText(searchText);

    const paragraph = paragraphs.find((paragraph) => {
      let result = false;
      searches.forEach((searchText) => {
        if(!result && paragraph.TextContent.toLocaleLowerCase().includes(searchText.toLowerCase())) {
          result = true;
        }
      });
      return result;
    });

    if(paragraph) {
      let searchedIndex = paragraph.TextContent.length;
      let findText = "";
      searches.forEach((searchText) => {
        const index = paragraph.TextContent.toLowerCase().indexOf(searchText.toLowerCase());
        if(index > -1 && searchedIndex > index) {
          searchedIndex = index;
          findText = searchText;
        }
      });

      const text = paragraph.TextContent;

      if(findText) {
        const parts = text.split(new RegExp(`(${_.escapeRegExp(searchText)})`, "gi"));

        parts.forEach((part) => {
          if(part.toLowerCase() === findText.toLowerCase()) {
            searchCount++;
          }
        });
      }
    }
    return searchCount === 0 ? null : searchCount;
  }

  function getHighlightedText(baseText: string, searchText: string) {
    if (searchText) {
      // Split text on highlight term, include term itself into parts, ignore case
      const parts = baseText.split(new RegExp(`(${_.escapeRegExp(searchText)})`, "gi"));

      return parts.map((part, index) => (
        <React.Fragment key={index}>
          {part.toLowerCase() === searchText.toLowerCase() ? (
            <b style={{ backgroundColor: fontColor.searchedTextBackground }}>{part}</b>
          ) : (
            part
          )}
        </React.Fragment>
      ));
    } else {
      return (<Box>{baseText}</Box>)
    }
  }

  const getSeparatedDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();

    return {year, month, day, hour};
  }

  const isAfter = (metaData: string) => {
    const labels = getRuleInstanceMetadata(metaData ?? "")?.label;
    if (!labels || labels.length === 0) return "";

    const today = new Date();
    const currentHours = today.getHours();
    const currentMinutes = today.getHours();
    const formattedCurrentTime = `${currentHours}:${currentMinutes}`;

    let dateStr: Date | null = null;
    let timeStr = "";
    labels.forEach((label) => {
      if(label.startsWith("ruleStartDate=")) {
        try {
          const dateValue = label.split("=")[1];
          dateStr = new Date(dateValue);
        }
        catch {
          console.log("Failed to convert ruleStartDate")
          dateStr = null;
        }
      }
      if(label.startsWith("ruleStartTime=") || label.startsWith(" ruleStartTime=")) {
        try {
          const timeValue = label.split("=")[1];
          timeStr = timeValue;
        }
        catch{
          timeStr = "";
        }
      }
    });

    if(dateStr) {
      const compareDate = new Date(dateStr);

      const currentDate = getSeparatedDate(today);
      const effectiveDate = getSeparatedDate(compareDate);

      if(currentDate.year > effectiveDate.year || (currentDate.year === effectiveDate.year && currentDate.month > effectiveDate.month) || (currentDate.year === effectiveDate.year && currentDate.month === effectiveDate.month && currentDate.day > effectiveDate.day)) {
        return true;
      }
      else if(currentDate.year === effectiveDate.year && currentDate.month === effectiveDate.month && currentDate.day === effectiveDate.day) {
        if(timeStr.length > 0) {
          if(formattedCurrentTime > timeStr) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const getTimeTill = (metaData: string) => {
    const labels = getRuleInstanceMetadata(metaData ?? "")?.label;
    if (!labels || labels.length === 0) return "";

    const today = new Date();
    let dateStr: Date | null = null;
    let differenceInMilliseconds = 0;
    let differenceInHours = 0;
    let timeStr = "";
    labels.forEach((label) => {
      if(label.startsWith("ruleStartDate=")) {
        try {
          const dateValue = label.split("=")[1];
          dateStr = new Date(dateValue);
        }
        catch {
          console.log("Failed to convert ruleStartDate")
          dateStr = null;
        }
      }
      if(label.startsWith("ruleStartTime=") || label.startsWith(" ruleStartTime=")) {
        try {
          const timeValue = label.split("=")[1];
          timeStr = timeValue;
        }
        catch{
          timeStr = "";
        }
      }
    });
    if(dateStr) {
      const compareDate = new Date(dateStr);

      const currentDate = getSeparatedDate(today);
      const effectiveDate = getSeparatedDate(compareDate);

      const compareTime = parseInt(timeStr);
      
      if(currentDate.year === effectiveDate.year && currentDate.month === effectiveDate.month && currentDate.day === effectiveDate.day) {
        if(timeStr.length > 0) {
          if(currentDate.hour < compareTime && compareTime - currentDate.hour < 24) {
            return `in ${compareTime - currentDate.hour} hours`;
          }
        }
      }

      differenceInMilliseconds = new Date(dateStr).getTime() - today.getTime();
      differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    }

    return (differenceInHours > 24 && differenceInHours < 168) ? `in ${(differenceInHours / 24).toFixed(0)} days` : "";
  }

  const getEffectiveDate = (metaData: string) => {
    const labels = getRuleInstanceMetadata(metaData ?? "")?.label;
    if (!labels || labels.length === 0) return "";

    let dateStr = "";
    let timeStr = "";
    labels.forEach(label => {
      if (label.startsWith("ruleStartDate=")) {
        try {
          const dateValue = label.split("=")[1];
          const date = new Date(dateValue);
          const dateTimeFormatter = new Intl.DateTimeFormat('en-NZ', { day: '2-digit', month: 'short' });
          dateStr = dateTimeFormatter.format(date);
        }
        catch {
          console.log("Failed to convert ruleStartDate")
          dateStr = "-"
        }
      }
      if (label.startsWith("ruleStartTime=") || label.startsWith(" ruleStartTime=")) {
        timeStr = " " + label.split("=")[1];
      }
    });
    return "Effective: " + dateStr + timeStr;
  }

  const getCreatedAtDate = (array: RuleInstance[] | undefined, array2: ContentRevision[] | undefined, id: string) => {
    if(!array || !array2) {
      return "";
    }

    const mergedArray = array.map((alert) => {
      const match = array2.find((alert2) => alert2.DisplayName === alert.DisplayName);
      if(match) {
        return {...alert, CreatedAt: match.CreatedAt}
      }
      return alert;
    })
    const alert: RuleInstance | Alert | undefined = mergedArray.find((alert) => alert?.RuleInstanceId === id);
    return new Date(alert?.CreatedAt ?? "").toLocaleDateString("en-AU");
  }

  const getDisplayNameElement = (ruleInstanceId: string, displayName: string, accessibleRuleIds: string[], isSelected: boolean) => {
    
    let num: string;
    let name: string;

    const index = displayName.indexOf(".");
    if (index > 0 && index < 3) {
      num = displayName.substring(0, index);
      name = displayName.substring(index + 1);
    }
    else {
      num = "";
      name = displayName;
    }
    const accessible = accessibleRuleIds.some(id => id === ruleInstanceId);

    return (
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignContent: "center" }}>
        {num.length > 0 ? (

          <Box sx={{
            marginRight: 1,
            border: "1px solid " + (accessible ? fontColor.ruleBeforeAccessible : fontColor.ruleBeforeInaccessible),
            borderRadius: "100%",
            fontWeight: 600,
            color: accessible ? fontColor.ruleBeforeAccessible : fontColor.ruleBeforeInaccessible,
            width: "22px",
            height: "22px",
            fontSize: "13px",
            textAlign: "center",
            display: "inline-block"
          }}>{num}</Box>
        ) : (
          <Box />
        )}
        <Typography sx={{
          fontWeight: isSelected ? 600 : '',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {name}
        </Typography>
      </Box >
    );
  }

  const isInHistory = (ruleId: string) => {
    return ruleUnitState.alertHistories?.some((alert) => alert.RuleId === ruleId) 
    || ruleUnitState.baseAlertHistories?.some((alert) => alert.RuleId === ruleId);
  }


  return (
    <>
      {
        prop.ruleInstances?.map((rule) => {

          const isSelected = ruleUnitState.selectedRule?.RuleInstanceId === rule.RuleInstanceId;
          const isDateAfterToday = isAfter(rule.Metadata ?? "");
          const isWithin24Hours = Number(getTimeTill(rule.Metadata ?? "")) < 24;

          return (
            <ListItem
              key={rule.RuleInstanceId}
              className="item-hover"
              data-element="RuleInstanceList_Item"
              onClick={() => {
                prop.onViewRuleDetail(rule);
              }}
              sx={{
                background: isSelected || isDateAfterToday && !isInHistory(rule.RuleId) ? fontColor.orangeHoverBackground : '',
                pl: 2,
                pr: 1,
                py: prop.narrowRowSpace ? 0.5 : 1,
                cursor: "pointer",
                display: "block",
                borderRadius: 1,
                borderBottom: (theme) => `solid 1px ${theme.palette.grey[300]}`,
                "&:hover, &:focus": {
                  background: fontColor.orangeHoverBackground
                }
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <ListItemText
                    primary={
                      <Box>
                        {getDisplayNameElement(rule.RuleInstanceId, rule.DisplayName, ruleUnitState.accessibleRuleInstanceIds ?? [], isSelected)}
                      </Box>
                    }
                    secondary={prop.secondaryTextFunction && prop.secondaryTextFunction(rule)}
                    secondaryTypographyProps={{
                      style: {
                        marginTop: "4px",
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  ></ListItemText>

                {ruleUnitState.searchText && ruleUnitState.alertRules?.filter((alert) => alert.RuleId === rule.RuleId).length === 0 && ruleUnitState.alertHistories?.filter((alert) => alert.RuleId === rule.RuleId).length === 0 ? 
                <Typography sx={{fontSize: 13, color: fontColor.orangeTitle}}>{getSearchedWordNumber(rule.Paragraphs, ruleUnitState.searchText ?? "")} results</Typography> 
                : null}

                {!isInHistory(rule.RuleId) ? 
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "end"}}>
                    {getCreatedAtDate(ruleUnitState.alertRules, ruleUnitState.baseAlertContentRevisions, rule.RuleInstanceId) !== "Invalid Date" ? 
                    <Typography sx={{fontSize: 13}}>{getCreatedAtDate(ruleUnitState.alertRules, ruleUnitState.baseAlertContentRevisions, rule.RuleInstanceId)}</Typography>
                    : null}
                    <Typography sx={{ fontSize: 13, color: isDateAfterToday || isWithin24Hours ? fontColor.redFontColor : fontColor.subTitleFontColor, fontWeight: isDateAfterToday || isWithin24Hours ? 500 : ""}}>
                      {isDateAfterToday ? "Overdue" : getTimeTill(rule.Metadata ?? "") ? getTimeTill(rule.Metadata ?? "") : ""}
                    </Typography>
                  </Box> : 
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "end"}}>
                    {getCreatedAtDate(ruleUnitState.alertHistories, ruleUnitState.baseAlertContentRevisions, rule.RuleInstanceId) !== "Invalid Date" ? 
                    <Typography sx={{fontSize: 13}}>{getCreatedAtDate(ruleUnitState.alertHistories, ruleUnitState.baseAlertContentRevisions, rule.RuleInstanceId)}</Typography>
                    : null}
                  </Box>}

                </Box>
                <Typography sx={{fontSize: 13}}>{getEffectiveDate(rule.Metadata ?? "")}</Typography>

                {ruleUnitState.myFavourites?.some(favourite => favourite.rule_id === rule.RuleId) && !prop.invisiblePinIcon && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                        justifyContent: 'right',
                        color: fontColor.orangeTitle
                      }} >
                      <PushPinIcon />
                    </ListItemIcon>
                  </Box>
                )}
              </Box>
              {
                (ruleUnitState?.searchText?.length && ruleUnitState?.searchText?.replace(/"/g, '').length > 1) ?
                  <Box>
                    {getHighlightedRuleText(rule.Paragraphs, ruleUnitState?.searchText ?? "")}
                  </Box>
                  :
                  <Box />
              }
              {prop.footerElement && prop.footerElement(rule)}
            </ListItem>
          )
        }
        )
      }
    </>
  )
};

export default RuleInstanceList;
