export enum Fonts {
  LIGHT = "300",
  REGULAR = "400",
  MEDIUM = "500",
  SEMI_BOLD = "600",
  BOLD = "700",
}

export const Locations = [
  "L1 - Pukekohe - Otiria and Branches",
  "L2 - Te Rapa - Pukekohe ECMT and Branches",
  "L3 - Waikanae - Te Rapa MNPL SOL PN",
  "L4 - Wellington - Waikanae JVL Wlg Ju",
  "L5 - Middleton - Picton",
  "L6 - Midland and West Coast",
  "L7 - Lyttelton - Invercargill and Branches"
]

export enum AlertViewMode {
  Alert = "alert",
  History = "history"
}

export const SELECTED_LOCATION_STORAGE_KEY = "wise-track-selected-locations";

export const escalatedMessage = "This message has been escalated";