import React, { ReactNode } from "react";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface SidebarWrapperProps {
  children: ReactNode;
  sidebarHidden?: boolean;
  setSidebarHidden?: (sidebarHidden : boolean) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const SidebarWrapper: React.FC<SidebarWrapperProps> = ({
  children,
  sidebarHidden,
  setSidebarHidden,
  ...rest
}) => {

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.5s ease",
        position: { xs: "relative", lg: "fixed" },
        top: 0,
        left: 0,
        zIndex: 1101,
        "& .app-sidebar": {
          position: "relative",
          top: "auto",
          left: "auto",
          width: "100%",
        },
      }}
      {...rest}
    >
      <Box sx={{ position: "absolute", top: "12px", right: "-12px", display: { xs: "none", lg: "block" } }}>
        <IconButton className="collapse-button" sx={{display: { xs: "none", md: "flex" }, color: "#000000", backgroundColor: "#ffffff", borderRadius: "9999px", zIndex: 99, border: "1px solid #d9d8db", '&:hover': { backgroundColor: '#f57c00', color: "#ffffff", border: "none" }}} onClick={() => setSidebarHidden?.(!sidebarHidden)}>
          <ChevronLeftIcon fontSize="small" className={`${sidebarHidden ? "rotate-180" : ""}`} />
        </IconButton>
      </Box>

      {children}
    </Box>
  );
};

export default SidebarWrapper;
