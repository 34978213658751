import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, Drawer } from "@mui/material";
import SidebarWrapper from "./Sidebar/SidebarWrapper";
import SidebarContent from "./Sidebar/SidebarContent";
import { AppState } from "../../redux/store";
import { toggleNavCollapsed } from "../../redux/actions/Setting";

interface AppSidebarProps {
  sidebarHidden: boolean;
  setSidebarHidden: (sidebarHidden: boolean) => void;
}

const AppSidebar: React.FC<AppSidebarProps> = ({ sidebarHidden, setSidebarHidden }) => {

  const dispatch = useDispatch();

  const navCollapsed = useSelector<AppState, AppState["settings"]>(
    ({ settings }) => settings
  ).navCollapsed;

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  return (
    <>
      <Hidden lgUp>
        <Drawer
          sx={{
            "& .MuiBackdrop-root": {
              background: "transparent"
            },
          }}
          variant="temporary"
          anchor="left"
          open={navCollapsed}
          onClose={handleToggleDrawer}
        >
          <SidebarWrapper className="app-sidebar">
            <SidebarContent />
          </SidebarWrapper>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <SidebarWrapper sidebarHidden={sidebarHidden} setSidebarHidden={setSidebarHidden} className="app-sidebar">
          <SidebarContent sidebarHidden={sidebarHidden} />
        </SidebarWrapper>
      </Hidden>
    </>
  );
};
export default AppSidebar;
